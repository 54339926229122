<template>
  <div class="row">
    <div class="col-lg-4">
      <div class="shadow-sm">
        <div class="card-header ">
          <h5><i class="fa fa-user"></i> Mon profil</h5>
          <small class="d-block text-muted text-right"
            >Compte créé {{ user.created_at | moment("from", "now") }}</small
          >
        </div>
        <div class="list-group">
          <router-link
            :to="{ name: 'profile' }"
            class="list-group-item list-group-item-action"
            :class="{ 'text-info': $route.name === 'profile' }"
          >
            Informations personnelles
          </router-link>
          <router-link
            :to="{ name: 'formPassword' }"
            class="list-group-item list-group-item-action"
            :class="{ 'text-info': $route.name === 'formPassword' }"
            >Changer le mot de passe</router-link
          >
        </div>
      </div>
    </div>
    <div class="col-lg-8">
      <div class="card">
        <div class="card-body">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Profile",
  computed: mapState("auth", ["user"])
};
</script>

<style scoped></style>
